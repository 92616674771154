import styled from 'styled-components'

import RenderCleanHtml from '../../../RenderCleanHtml'

export const ItemDetailsRenderCleanHtml = styled(RenderCleanHtml)`
  font-family: ${({ theme }) => theme.fontFamilies.roboto};
  margin-bottom: 0;

  p,
  ul,
  ol {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    // reduce margin slightly (default is 24pt) and apply to ul and ol
    margin-bottom: 1.5em;
  }
  ul,
  ol {
    padding-left: 2em;
  }
  li {
    margin-bottom: 0.3em;
  }
`
