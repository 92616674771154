import { ApolloError, ApolloQueryResult, DefaultContext, TypedDocumentNode } from '@apollo/client'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import { catchError, defer, map, Observable, retry, switchMap, take, timer } from 'rxjs'

import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { fromObservableQuery } from '../../utils/rx/observable-query'
import { latestCommaClient$ } from '../graphql/apollo'

export interface SearchQueryLeft {
  _tag: 'Left'
  error: ApolloError | Error
}

export interface SearchQueryRight<TData = unknown> {
  _tag: 'Right'
  data: TData
}

export type SearchQueryResult<TData = unknown> = SearchQueryLeft | SearchQueryRight<TData>

const toSearchQueryResult = <TData = unknown>({
  error,
  data,
}: ApolloQueryResult<TData>): SearchQueryResult<TData> =>
  // assumes errorPolicy === 'none' (error should have been thrown, but we'll check just in case)
  error
    ? {
        _tag: 'Left' as const,
        error: error,
      }
    : { _tag: 'Right' as const, data: data }

/**
 * Execute query using `ApolloClient::watchQuery`. Uses latest token if logged in and watches for
 * cache updates.
 */
export const commaQueryFactory =
  <TData = unknown, TVariables = unknown>(query: TypedDocumentNode<TData, TVariables>) =>
  (options: Omit<QueryOptions<TVariables, TData>, 'query'>): Observable<SearchQueryResult<TData>> =>
    latestCommaClient$.pipe(
      take(1),
      switchMap((client) =>
        defer(() =>
          fromObservableQuery(
            client.watchQuery<TData, TVariables>({
              ...options,
              query,
              context: {
                ...options.context,
                useLatestTokenIfLoggedIn: true,
              } as DefaultContext,
            }),
          ),
        ).pipe(
          // retry network errors
          retry({
            count: 3,
            delay: (_, count) =>
              // retry with exponential backoff and max of 60 seconds
              timer(Math.min(60000, 2 ^ (count * 1000))),
          }),
          map((result) => toSearchQueryResult(result)),
          catchError(coerceCaughtToLeft),
        ),
      ),
    )
