import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import styled from 'styled-components'

export const Separator = styled.span`
  display: block;
  margin: 20px auto;
  border-bottom: 1px solid #ccc;
  width: 90%;
`

export const LinkAndCtaContainer = styled.div`
  margin-top: auto;
`

export const BannerAddToCartButton = styled(PrimaryButton)`
  height: 46px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0;
  border: none;
  border-radius: 8px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1200px) {
    font-size: 1rem;
  }
`

export const ProductPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 0 8px 0;
  width: 100%;
`

export const ScrollableContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  gap: 4px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  },
`

export const ProductMinimumPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.blackPearlBlue.shade700};
`

export const ProductMinimumPriceText = styled.span`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 325;
  line-height: 12px;
  margin-bottom: 4px;
`

export const ProductMinimumPriceAmount = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 600;
  line-height: 19.2px;
`

export const ProductRetailPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutralGray.neutral500};
`

export const ProductRetailPriceText = styled.span`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 325;
  line-height: 12px;
  margin-bottom: 4px;
`

export const ProductRetailPriceAmount = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 600;
  line-height: 19.2px;
`

export const ProductBannerCardContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grayscale.white,
  border: `1px solid ${theme.colors.coolGray.cool150}`,
  borderRadius: '5px',
  display: 'flex',
  flex: '1 0 100%',
  minHeight: '382px',
  height: '100%',
  hyphens: 'auto',
  justifyContent: 'flex-start',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  boxSizing: 'border-box',
  flexDirection: 'column',
  paddingTop: '20px',
  a: {
    color: theme.colors.grayscale.black,
    textDecoration: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  h4: {
    color: '#2152ac',
    padding: '0 15px 8px 0',
    '@media(min-width: 1440px)': {
      fontSize: '18px',
    },
  },
  img: {
    height: '175px',
    width: '175px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  '.product-image': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    img: {
      alignSelf: 'center',
    },
    a: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  '.product-details': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: '0 15px 15px',
    marginBottom: '0',
    '.small-paragraph.small-margin-bottom': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '@media(min-width: 1440px)': {
        fontSize: '14px',
      },
    },
  },
  '.tagline': {
    textTransform: 'uppercase',
  },
  '@media(min-width: 600px)': {
    flex: '0 0 calc(50%/6)',
    maxWidth: '260px',
    minWidth: '200px',
  },
  '@media(min-width: 875px)': {
    flex: '0 0 calc(100%/6)',
    width: 'calc(100%/6)',
  },
  '@media(min-width: 1024px)': {
    marginBottom: '20px',
  },
}))
