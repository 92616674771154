import { bind } from '@react-rxjs/core'
import { createKeyedSignal } from '@react-rxjs/utils'
import { startWith, switchMap } from 'rxjs'

import { right } from '../../utils/result'
import { mapRight } from '../../utils/rx/operators'
import { searchExactQuery } from '../search/exact-query'

const keyForSkus = (skus: string[]) => JSON.stringify(skus.slice().sort())

const [searchProductsBySkus$, searchProductsBySkus] = createKeyedSignal(keyForSkus)

export { searchProductsBySkus }

const productsBySkus$ = (skus: string[]) =>
  searchProductsBySkus$(keyForSkus(skus)).pipe(
    switchMap(() =>
      searchExactQuery({ skus }).pipe(
        mapRight((value) => right({ products: value.data })),
        startWith(right({ products: [] })),
      ),
    ),
  )

export const [useProductsBySkus, latestProductsBySkus$] = bind(productsBySkus$)
