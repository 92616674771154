import BlockContent, { BlockContentProps } from '@sanity/block-content-to-react'
import React from 'react'

import { SanityParentProductFragment } from '../../../graphql/gatsby'
import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../graphql/magento'
import { useSanityContextBasedContent, useSanityRoleBasedContent } from '../../../lib/sanity'
import { SanityContext } from '../../../lib/sanity/context-based-content'
import { SanityUserRole } from '../../../lib/sanity/roles'
import { displayAttribute } from '../../../utils/itemTools'
import { ItemDetailsRenderCleanHtml } from '../../shop/pdp/item_details/ItemDetailsRenderCleanHtml'
import { serializers } from './serializers'
import { PortableTextContainer } from './styled'

/*
    Example of contextualDescription:
    [{
      _key: e64ee27d81bf,
      _type: contextualDescription,
      contexts: { _type: contextBasedContent, enabled: false },
      roles: { _type: roleBasedContent, enabled: true, roles: [CAT_PATIENT, CAT_PRACTITIONER] },
      description: [BlockContent]
    }, 
    {
      _key: e27bb7638e51,
      _type: contextualDescription,
      contexts: { _type: contextBasedContent, enabled: true, contexts: [WEBSTORE, DISPENSARY]},
      roles: { _type: roleBasedContent, enabled: true, roles: [CAT_EMPLOYEE] },
      description: [BlockContent]
    }]
  */

type ContextualDescription = {
  _key: string
  _type: string
  contexts: {
    _type: string
    enabled: boolean
    contexts: SanityContext[]
  }
  roles: {
    _type: string
    enabled: boolean
    roles: string[]
  }
  description: BlockContentProps['blocks']
}

export interface ProductDescriptionTextProps {
  item: ConfigurableProductDetailsFragment
  selectedVariant: SimpleProductDetailsFragment | null | undefined
  sanityParentProduct?: SanityParentProductFragment | null
  sanityChildProduct?: NonNullable<SanityParentProductFragment['products']>[number] | null
  className?: string
}

const checkAuthenticatedRole = (
  roles: ContextualDescription['roles'],
  userRoles?: readonly SanityUserRole[],
) => {
  if (!userRoles || userRoles.includes(SanityUserRole['Anonymous'])) {
    return !!roles.roles?.includes('ANONYMOUS')
  }
  return userRoles.some((role) => !!roles.roles?.includes(role))
}

/**
 * Get the first contextual description that matches the current context and user roles
 * @param currentContext - The current context (dispensary or webstore)
 * @param userRoles - The roles the current user has
 * @returns boolean
 */
const findByRolesAndContexts =
  (currentContext: SanityContext | null, userRoles?: readonly SanityUserRole[]) =>
  ({ roles, contexts }: ContextualDescription): boolean => {
    if (currentContext) {
      if (!roles.enabled && !contexts.enabled) {
        return false
      } else if (!roles.enabled && contexts.enabled) {
        return !!contexts.contexts?.includes(currentContext)
      } else if (roles.enabled && !contexts.enabled) {
        return checkAuthenticatedRole(roles, userRoles)
      } else {
        return (
          checkAuthenticatedRole(roles, userRoles) && !!contexts.contexts?.includes(currentContext)
        )
      }
    } else {
      return false
    }
  }

const ProductDescriptionText: React.VFC<ProductDescriptionTextProps> = ({
  item,
  selectedVariant,
  sanityParentProduct,
  sanityChildProduct,
  className,
}) => {
  const { currentContext } = useSanityContextBasedContent()
  const { userRoles } = useSanityRoleBasedContent()

  const contextualChildDescriptions: BlockContentProps['blocks'][] | undefined = (
    sanityChildProduct?._rawContextualDescription ?? []
  )
    ?.filter(findByRolesAndContexts(currentContext, userRoles))
    .map((desc: ContextualDescription) => desc.description)

  const contextualParentDescriptions: BlockContentProps['blocks'][] | undefined = (
    sanityParentProduct?._rawContextualDescription ?? []
  )
    ?.filter(findByRolesAndContexts(currentContext, userRoles))
    .map((desc: ContextualDescription) => desc.description)

  // Order of precedence for the descriptions:
  // 1. Contextual Child Description (Sanity)
  // 2. Default Child Description (Sanity)
  // 3. Contextual Parent Description (Sanity)
  // 4. Default Parent Description (Sanity)
  // 5. Short Description (Magento)

  return (
    <PortableTextContainer className={className}>
      {contextualChildDescriptions?.length ? (
        contextualChildDescriptions.map((desc, i) => (
          <BlockContent blocks={desc} serializers={serializers} key={i} />
        ))
      ) : sanityChildProduct?._rawDefaultDescription ? (
        <BlockContent
          blocks={sanityChildProduct?._rawDefaultDescription}
          serializers={serializers}
        />
      ) : contextualParentDescriptions?.length ? (
        contextualParentDescriptions.map((desc, i) => (
          <BlockContent blocks={desc} serializers={serializers} key={i} />
        ))
      ) : sanityParentProduct?._rawDefaultDescription ? (
        <BlockContent
          blocks={sanityParentProduct?._rawDefaultDescription}
          serializers={serializers}
        />
      ) : (
        <ItemDetailsRenderCleanHtml
          htmlString={displayAttribute({
            item: item.short_description,
            selectedVariant: selectedVariant?.short_description,
            attribute: 'html',
          })}
        />
      )}
    </PortableTextContainer>
  )
}

export default ProductDescriptionText
