import styled, { css } from 'styled-components'

const sharedListStyles = css<{ $level: number }>`
  margin-left: ${({ $level }) => ($level === 1 ? 0 : '0.75rem')};
`

const bulletTypes = ['square', 'disc', 'circle']
export const BulletList = styled.ul<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => bulletTypes[$level % bulletTypes.length]};
`

const numberTypes = ['lower-roman', 'decimal', 'lower-alpha']
export const NumberedList = styled.ol<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => numberTypes[$level % numberTypes.length]};
`

export const PortableTextContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.roboto};

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    line-height: 1.5;
  }

  h1 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  h5 {
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  p,
  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    padding-inline-start: 1.15em;
  }

  li {
    margin-top: 0.5em;
  }

  a[href] {
    color: ${({ theme }) => theme.colors.component.link};
    // avoid wrapping anchor text when possible
    display: inline-block;
  }
`
