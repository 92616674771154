import BlockContent, {
  BlockContentProps,
  ListRendererProps,
  TypeSerializerProps,
} from '@sanity/block-content-to-react'
import React from 'react'

import { BulletList, NumberedList } from './styled'

const BlockRenderer: React.FC<TypeSerializerProps> = (props) => {
  const { style = 'normal' } = props.node ?? {}
  switch (style) {
    default:
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const ListRenderer: React.FC<ListRendererProps> = ({ children, type, level }) =>
  type === 'bullet' ? (
    <BulletList $level={level}>{children}</BulletList>
  ) : (
    <NumberedList $level={level}>{children}</NumberedList>
  )

export const serializers: BlockContentProps['serializers'] = {
  types: {
    block: BlockRenderer,
  },
  list: ListRenderer,
}
