import BlockContent, {
  BlockContentProps,
  ListRendererProps,
  TypeSerializerProps,
} from '@sanity/block-content-to-react'
import React from 'react'
import styled, { css } from 'styled-components'

import { headingFontSize } from '../../theme/helpers'

export const Subheading1 = styled.p``
export const Subheading2 = styled.p``
export const Subheading3 = styled.p``
export const Subheading4 = styled.p``

const BlockRenderer: React.FC<TypeSerializerProps> = (props) => {
  const { style = 'normal' } = props.node ?? {}
  switch (style) {
    case 'subheading1':
      return <Subheading1>{props.children}</Subheading1>
    case 'subheading2':
      return <Subheading2>{props.children}</Subheading2>
    case 'subheading3':
      return <Subheading3>{props.children}</Subheading3>
    case 'subheading4':
      return <Subheading4>{props.children}</Subheading4>
    default:
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const sharedListStyles = css<{ $level: number }>`
  margin-left: ${({ $level }) => ($level === 1 ? 0 : '0.75rem')};
`

const bulletTypes = ['square', 'disc', 'circle']
const BulletList = styled.ul<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => bulletTypes[$level % bulletTypes.length]};
`

const numberTypes = ['lower-roman', 'decimal', 'lower-alpha']
const NumberedList = styled.ol<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => numberTypes[$level % numberTypes.length]};
`

const ListRenderer: React.FC<ListRendererProps> = ({ children, type, level }) =>
  type === 'bullet' ? (
    <BulletList $level={level}>{children}</BulletList>
  ) : (
    <NumberedList $level={level}>{children}</NumberedList>
  )

const serializers: BlockContentProps['serializers'] = {
  types: {
    block: BlockRenderer,
  },
  list: ListRenderer,
}

export interface BodyPortableTextProps {
  className?: string
  blocks: BlockContentProps['blocks']
}

const BodyPortableText: React.VFC<BodyPortableTextProps> = ({ className, blocks }) => (
  <div className={className}>
    <BlockContent blocks={blocks} serializers={serializers} />
  </div>
)

const StyledBodyPortableText = styled(BodyPortableText)`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};

  h1,
  h2,
  h3,
  h4,
  h5,
  ${Subheading1}, ${Subheading2}, ${Subheading3}, ${Subheading4} {
    color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol {
    line-height: 1.5;
  }

  h1 {
    font-size: ${({ theme }) => headingFontSize({ size: 4, theme })};
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: ${({ theme }) => headingFontSize({ size: 3, theme })};
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    font-size: ${({ theme }) => headingFontSize({ size: 1, theme })};
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  h5 {
    font-size: ${({ theme }) => headingFontSize({ size: 0, theme })};
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  p,
  ul,
  ol {
    font-size: 14px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    padding-inline-start: 1.15em;
  }

  li {
    margin-top: 0.5em;
  }

  a[href] {
    color: ${({ theme }) => theme.colors.component.link};
    // avoid wrapping anchor text when possible
    display: inline-block;
  }

  ${Subheading1} {
    font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
  }

  ${Subheading2} {
    font-size: ${({ theme }) => headingFontSize({ size: 1, theme })};
  }

  ${Subheading3} {
    font-size: ${({ theme }) => headingFontSize({ size: 1, theme })};
  }

  ${Subheading4} {
    font-size: ${({ theme }) => headingFontSize({ size: 0, theme })};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    ~ ${Subheading1} {
      margin-top: -0.75em;
    }
    ~ ${Subheading2} {
      margin-top: -0.75em;
    }
    ~ ${Subheading3} {
      margin-top: -0.75em;
    }
    ~ ${Subheading4} {
      margin-top: -0.75em;
    }
  }
`

export default StyledBodyPortableText
