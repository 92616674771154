import { Observable, of } from 'rxjs'

import {
  SearchExactParentOrChildSkuDocument,
  SearchExactParentOrChildSkuQuery,
} from '../../graphql/search'
import { right } from '../../utils/result'
import { mapRight } from '../../utils/rx/operators'
import { commaQueryFactory, SearchQueryResult } from './query'

export type SearchExactProduct = SearchExactParentOrChildSkuQuery['exact']['results'][number]

const exactParentOrChildSkuQuery = commaQueryFactory(SearchExactParentOrChildSkuDocument)

const mapProductsBySku = (products: SearchExactProduct[]): Map<string, SearchExactProduct> =>
  products.reduce((acc, product) => {
    acc.set(product.sku, product)
    return acc
  }, new Map<string, SearchExactProduct>())

export const searchExactQuery = ({
  skus,
}: {
  skus: string[]
}): Observable<SearchQueryResult<SearchExactProduct[]>> =>
  !skus || skus.length === 0
    ? of({ _tag: 'Right' as const, data: [] })
    : exactParentOrChildSkuQuery({
        // sort skus for efficient caching
        variables: { sku: skus.slice().sort() },
      }).pipe(
        mapRight(({ data }) =>
          right(
            // convert list to map for efficient product retrieval (by sku)
            mapProductsBySku(data?.exact?.results ?? []),
          ),
        ),
        mapRight(({ data: products }) =>
          right(
            skus
              .map(Map.prototype.get.bind(products))
              .filter((product): product is SearchExactProduct => !!product),
          ),
        ),
      )
